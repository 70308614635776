import React, { useState } from 'react';
import AnimationCanvas from './components/AnimationCanvas';

function App() {
  // ログインフラグ
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // ローディング状態
  const [isLoading, setIsLoading] = useState(false);


  // ログイン処理
  const handleLogin = async (inputEmail) => {
    try {
      setIsLoading(true);
      console.log('ログイン試行:', inputEmail);

      // 開発環境とプロダクション環境でエンドポイントを切り替え
      const apiUrl = process.env.NODE_ENV === 'production' 
        ? '/api/auth'  // 本番環境（相対パス）
        : 'http://localhost:3000/api/auth';  // 開発環境

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: inputEmail })
      });
      
      console.log('サーバーレスポンス:', response.status);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('レスポンスデータ:', data);
      
      if (data.isAuthorized) {
        setIsLoggedIn(true);
      } else {
        alert('メールアドレスが許可されていません。kanri@kandaquantum.co.jp or 元木XのDMにお問い合わせください。');
      }
    } catch (error) {
      console.error('認証処理中にエラーが発生しました:', error);
      alert('認証処理中にエラーが発生しました。もう一度お試しください。');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* AnimationCanvasは常に表示 */}
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}>
        <AnimationCanvas />
      </div>

      {/* ログインしていない場合は半透明のオーバーレイとログインフォームを表示 */}
      {!isLoggedIn && (
        <div style={overlayStyle}>
          <LoginOverlay onLogin={handleLogin} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
}

// オーバーレイスタイル
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0,0.5)',
  backdropFilter: 'blur(3px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '10vh',
  zIndex: 2,
};

// フォームコンテナスタイル
const formContainerStyle = {
  backgroundColor: 'rgba(28, 42, 56, 0.7)',
  padding: '30px',
  borderRadius: '12px',
  color: '#fff',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px 0 rgba(0, 255, 255, 0.15)',
  border: '1px solid rgba(0, 255, 255, 0.1)',
  maxWidth: '400px',
  width: '90%',
  marginTop: '-5vh',
  background: 'linear-gradient(135deg, rgba(28, 42, 56, 0.7) 0%, rgba(17, 34, 48, 0.8) 100%)',
};

// フォームスタイル
const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
};

// 入力フィールドスタイル
const inputStyle = {
  padding: '12px',
  fontSize: '16px',
  borderRadius: '6px',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#fff',
  outline: 'none',
  transition: 'all 0.3s ease',
  '::placeholder': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
};

// ボタンスタイル
const buttonStyle = {
  padding: '12px',
  fontSize: '16px',
  backgroundColor: 'rgba(255, 214, 0, 0.8)',
  color: '#000',
  borderRadius: '6px',
  border: 'none',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  fontWeight: '600',
  ':hover': {
    backgroundColor: 'rgba(255, 214, 0, 1)',
  },
  ':disabled': {
    backgroundColor: 'rgba(255, 214, 0, 0.5)',
    cursor: 'not-allowed',
  },
};

// ログインフォームコンポーネント
function LoginOverlay({ onLogin, isLoading }) {
  const [email, setEmail] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    await onLogin(email);
  };

  return (
    <div style={formContainerStyle}>
      <h2 style={{ color: '#fff', marginBottom: '20px', textAlign: 'center' }}>
        メールアドレスを入力してください
      </h2>
      <form onSubmit={submitHandler} style={formStyle}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
          disabled={isLoading}
          required
        />
        <button type="submit" style={buttonStyle} disabled={isLoading}>
          {isLoading ? 'ログイン中...' : 'ログイン'}
        </button>
      </form>
    </div>
  );
}

export default App;